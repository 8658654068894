<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-tabs
        v-model="tab"
        background-color="white"
        grow
      >
        <v-tabs-slider color="primary" />
        <v-tab
          v-for="(item, idx) in tabs"
          :key="idx"
        >
          {{ item.name }}
        </v-tab>

        <!----------------------------
          Pagina de Login
        ----------------------------->
        <v-tab-item>
          <v-card
            flat
            class="mt-5"
          >
            <v-card-text>
              <v-form
                ref="loginForm"
                v-model="valid"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <h6 class="text-center text-h6">Faça seu login</h6>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="client.email"
                      :rules="loginEmailRules"
                      label="E-mail*"
                      dense
                      rounded
                      hide-details
                      outlined
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="client.password"
                      :append-icon="show1 ? 'eye' : 'eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      label="Senha*"
                      dense
                      hide-details
                      rounded
                      outlined
                      @click:append="show1 = !show1"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                width="60%"
                class="mx-auto"
                rounded
                large
                :disabled="!client.email || !client.password"
                color="primary"
                @click="validate()"
              >
                Entrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>

        <!----------------------------
          Pagina de Cadastro
        ----------------------------->
        <v-tab-item>
          <v-card
            flat
            class="mt-5"
          >
            <v-card-text>
              <v-form
                ref="registerForm"
                v-model="valid"
                lazy-validation
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <h6 class="text-center text-h6">Crie seu cadastro</h6>
                    <p
                      :class="{
                        'text-center': true,
                        'error--text': !isValidDocument || !is_client_valid,
                      }"
                    >
                      {{
                        isValidDocument == false
                          ? 'Digite um documento válido'
                          : is_client_valid == true
                          ? 'Já existe um usuário com esse documento'
                          : register.person_type === 'PJ'
                          ? 'Digite os dados da sua empresa'
                          : 'Informe seus dados'
                      }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="register.cpf_cnpj"
                      @blur="checkDocument"
                      :rules="[rules.required]"
                      :loading="checkingDocument"
                      label="Informe seu CPF ou CNPJ*"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      hide-details
                      dense
                      rounded
                      outlined
                      required
                      :append-icon="isValidDocument && 'far fa-check-circle'"
                    />
                  </v-col>

                   <!-- <v-col
                    v-if="
                      is_client_valid === true && register.person_type === 'PJ'
                    "
                    cols="12"
                  > -->
                  <v-col
                    v-if="register.person_type === 'PJ'"
                    cols="12"
                  >
                    <v-text-field
                      v-model="register.company_name"
                      label="Razão Social*"
                      :rules="[
                        () => !!register.company_name || 'Campo Obrigatório',
                      ]"
                      dense
                      rounded
                      outlined
                      hide-details
                      required
                    />
                  </v-col>
                  <v-col
                    v-if="
                     register.person_type === 'PJ'
                    "
                    cols="12"
                  >
                    <v-text-field
                      v-model="register.trading_name"
                      label="Nome Fantasia*"
                      :rules="[
                        () => !!register.trading_name || 'Campo Obrigatório',
                      ]"
                      dense
                      rounded
                      hide-details
                      outlined
                      required
                    />
                  </v-col>
                  <v-col
                    v-if="
                      register.person_type === 'PJ'
                    "
                    cols="12"
                  >
                    <v-autocomplete
                      v-model="register.business_id"
                      label="Ramo de Atuação*"
                      :items="line_of_business"
                      item-text="name"
                      item-value="id"
                      dense
                      hide-details
                      rounded
                      outlined
                    />
                  </v-col>

                  <v-col cols="12">
                    <h6 class="text-center text-h6">Dados de contato</h6>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="register.contact_name"
                      :rules="[
                        () => !!register.contact_name || 'Campo Obrigatório',
                      ]"
                      label="Nome Completo"
                      dense
                      rounded
                      hide-details
                      outlined
                      required
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    v-if="
                      register.person_type === 'PJ'
                    "
                  >
                    <v-text-field
                      v-model="register.department"
                      label="Departamento"
                      dense
                      rounded
                      hide-details
                      outlined
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="register.contact"
                      :rules="[() => !!register.contact || 'Campo Obrigatório']"
                      label="Telefone*"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      dense
                      hide-details
                      rounded
                      outlined
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="register.email"
                      :rules="loginEmailRules"
                      label="E-mail*"
                      dense
                      hide-details
                      rounded
                      outlined
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="register.password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      label="Senha*"
                      hint="No mínimo 6 characters"
                      dense
                      rounded
                      hide-details
                      outlined
                      @click:append="show1 = !show1"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, (v) => v === register.password]"
                      :type="show1 ? 'text' : 'password'"
                      label="Confirmar senha*"
                      dense
                      rounded
                      outlined
                      hide-details
                      block
                      @click:append="show1 = !show1"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                rounded
                large
                class="mx-auto"
                width="60%"
                hide-details
                :disabled="!valid"
                color="primary"
                @click="registerClient"
              >
                Cadastrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import validateCNPJ from '@/mixins/validateCNPJ'
import validateCPF from '@/mixins/validateCPF'
export default {
  prop: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [validateCNPJ, validateCPF],
  data: () => ({
    line_of_business: [],
    valid: false,
    show1: false,
    is_logged: false,
    checkingDocument: false,
    cnpj_validation: true,
    is_client_valid: null,
    isValidDocument: null,
    register: {
      type: 'client',
      cpf_cnpj: null,
      person_type: null,
      is_contact: false,
      business_id: null,
    },
    verify: null,
    client: { email: null, password: null },
    client_name: null,
    client_company_name: null,
    client_trading_name: null,
    rules: {
      required: (value) => !!value || 'Campo Obrigatório',
      min: (v) => (v && v.length >= 6) || 'Mínimo 6 characteres',
      equal: (v) => v === this.register.password || 'Senha não confere',
    },
    loginEmailRules: [
      (v) => !!v || 'Campo Obrigatório',
      (v) => /.+@.+\..+/.test(v) || 'Digite um email válido',
    ],
    tab: 0,
    tabs: [
      { name: 'Entrar', icon: 'mdi-login-variant' },
      { name: 'Cadastrar', icon: 'mdi-account-arrow-right' },
    ],
  }),
  computed: {
    client_name_computed() {
      return this.client_name ? this.client_name : this.client_company_name
    },
  },
  filters: {
    format_name(value) {
      return (
        value.slice(0, 1).toUpperCase() +
        value.split(' ')[0].slice(1).toLowerCase()
      )
    },
  },
  methods: {
    getBusiness() {
      this.$api
        .get('lines')
        .then((res) => {
          this.line_of_business = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    checkDocument() {
      if (this.register.cpf_cnpj != null && this.register.cpf_cnpj != '') {
        this.checkingDocument = true
        this.checkDocType()
        this.$api
          .post(`people/doc`, { cpf_cnpj: this.register.cpf_cnpj })
          .then((res) => {
            this.cnpj_validation = false
            this.is_client_valid = res.data.data
            this.client_name = res.data.name
            this.client_company_name = res.data.company_name
            this.client_trading_name = res.data.trading_name
            if (this.is_client_valid == true) {
              this.register.is_contact = true
            }
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            this.checkingDocument = false
          })
      }
    },
    checkDocType() {
      if (this.register.cpf_cnpj.length <= 14) {
        this.register.person_type = 'PF'
        this.isValidDocument = this.validateCPF(this.register.cpf_cnpj)
      } else {
        this.register.person_type = 'PJ'
        this.isValidDocument = this.validateCNPJ(this.register.cpf_cnpj)
      }
    },
    validate() {
      this.login()
      // console.log(this.$refs.loginForm.validate())
      // if (this.$refs.loginForm.validate()) {
      //   this.login()
      // }
    },
    docNumberErrorMessage() {
      this.$store.commit('setModal', {
        show: true,
        component: 'error',
        text: 'Ops! Número do documento não pode estar vazio.',
        caption: 'Verifique se os campos estão preenchidos corretamente.',
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    errorMessage(err) {
      this.$store.commit('setModal', {
        show: true,
        component: 'error',
        text: 'Ops!',
        caption: err.response.data.message,
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    successRegisterMessage() {
      this.$store.commit('setModal', {
        show: true,
        component: 'success',
        text: 'Cadastro realizado com Sucesso!',
        caption: 'Visite seu perfil e complete os dados que faltam.',
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
          location.reload()
        },
      })
    },
    login() {
      console.log(this.client)
      this.$api
        .post('/auth/login/?src=site', this.client)
        .then((res) => {
          this.$session.start()

          let name =
            res.data.user.name != null
              ? res.data.user.name
              : res.data.user.company_name
          this.$cookies.set('name', name)
          this.$cookies.set('token', res.data.token)
          this.$cookies.set('isAuth', res.data.user.id)
          this.$cookies.set('profile', res.data.profile)
          this.$cookies.set('type', res.data.type)
          this.$cookies.set('id', res.data.id)
          this.$cookies.set('contact_id', res.data.contact_id)
          setTimeout(() => {
            this.modulesField = true
            this.isLogged = true
          }, 1800)
          //forçar a atualização da página
          location.reload()
        })
        .catch((err) => {
          this.errorMessage(err)
        })
    },
    registerClient() {
      if (this.$refs.registerForm.validate()) {
        if (this.register.cpf_cnpj != null && this.register.cpf_cnpj != '') {
          this.$api
            .post('/auth/register', this.register)
            .then((res) => {
              this.$cookies.set('name', this.register.contact_name)
              this.$cookies.set('token', res.data.user.token)
              this.$cookies.set('isAuth', res.data.user.id)
              this.$cookies.set('profile', res.data.user.profile)
              this.$cookies.set('type', res.data.user.type)
              this.$cookies.set('id', res.data.user.id)
              this.successRegisterMessage()
            })
            .catch((err) => {
              this.errorMessage(err)
            })
        } else {
          this.docNumberErrorMessage()
        }
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops!',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
  },
  created() {
    this.getBusiness()
  },
}
</script>
